<template>
  <v-app id="inspire">
  <div>
    <title>เมนูสำหรับสมาชิก</title>
    <img
      src="../assets/image/UI Member PHOTO/Banner-02.png"
      class="img-fluid"
      alt="Responsive image"
      style="width: 100%;"
    />
    <template v-if="$store.state.profile.branchWW != null">

    <v-card style="width: 95%; margin:  0 auto 30px auto;">
      <div style="padding-bottom: 10px;">
      <v-toolbar style="padding: 0px;">
          <v-tabs v-model="tabmenber">
            <v-tabs-slider style="background-color:#392193;"></v-tabs-slider>
            <v-tab v-on:click="cssTab(1)" :href="`#tab-1`" :style="listcsstab[0]">
              <p style="font-size:18px; margin: 0;">Member</p>
            </v-tab>
            <v-tab v-on:click="cssTab(2)" :href="`#tab-2`" :style="listcsstab[1]">
              <p style="font-size:18px; margin: 0;">History</p>
            </v-tab>
          </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tabmenber" >
        <v-tab-item :value="`tab-1`" >
          <div style="display: flex; padding-top: 10px; padding-bottom: 20px">
            <div style="width: 30%; text-align: center;">
              <img :src="picprofile" class="img-fluid" alt="Responsive image" style="width: 40px; height: 40px; border-radius: 50%;"/>
            </div>
            <div style="width: 70%;">
              <h1 style="font-size: 15px; margin: 0; color:#7155c6; ">{{textShowL1}}</h1>
              <h1 style="font-size: 15px; margin: 0 0; ">{{textShowL2}}</h1>
            </div>
          </div>

        <div>
          <center>
          <CCard style="width: 95%;">
            <CCardBody style="background-color:#e5e5e5;">
                <v-skeleton-loader v-if="$store.state.profile.branchWW.length <= 1" style="height: 35px;" type="image"></v-skeleton-loader>
                <CSelect id="selectTab1" :value="$store.state.profile.branch" @change="onChangeBranch($event)" :options="$store.state.profile.branchWW" style="font-family: Kanit, sans-serif;" />
                <div style="display: flex;">
                  <div style="margin: 0 auto;">
                    <h3 style="font-size:14px; margin: 0 0;">เครดิตคงเหลือ</h3>
                    <h3 style="font-size:10px; color:#7D7D7D; margin: 0 0 0 -17px;">Credit balance</h3>
                    <div style="display: flex;">
                      <div>
                        <img src="../assets/image/UI Member PHOTO/Member icon-04.png" class="img-fluid" alt="Responsive image" style="width: 22px; padding-top: 5px; margin: 0 5px 0 0;">
                      </div>
                      <v-skeleton-loader v-if="loadingdata.credit == true" style="width: 50px; height: 30px; margin-top: 5px;" type="image"></v-skeleton-loader>
                      <!-- <v-skeleton-loader v-if="loadingdata.coupons" style="width: 50px; height: 30px; margin-top: 5px;" type="image"></v-skeleton-loader> -->
                      <template v-else>
                        <h1 style="font-size:25px; font-weight: bold; padding-top: 6px; padding-left: 0px">{{ Number($store.state.profile.money).toLocaleString() }} </h1>
                        <h1 style="font-size:12px; color:#7D7D7D; padding-top: 18px; padding-left: 3px;">บาท</h1>
                      </template>
                    </div>
                  </div>
                  <div style="margin: auto auto;">
                  </div>
                </div>
            </CCardBody>
          </CCard>
          </center>
          
          <!-- <v-skeleton-loader v-if="loadingdata.coupons" style="width: 95%; margin: 0 auto 0 auto;" type="image"></v-skeleton-loader> -->
          <v-skeleton-loader v-if="loadingdata.credit == true" style="width: 95%; margin: 0 auto 0 auto;" type="image"></v-skeleton-loader>
          <template v-else>
            <!-- <hr v-if="items_promotion.length > 0" > -->
            <nav v-if="showTextPromotion == true" style="font-size:16px; font-weight: bold; padding-left: 10px; margin-bottom: 5px">โปรโมชั่นที่ร่วมรายการ</nav>
            <center v-for="item in items_promotion" :key="item.id">
            <CCard style="width: 95%; margin: 0 auto 10px auto;" v-if="item.title != 'WonderWash' && item.typeId != 1 && item.typeId != 4">
              <CCardBody v-if="item.display == true" style="background-color:#e5e5e5; padding: 20px;">
                <div>
                  <div style="display: flex; ">
                    <div style="width: 100%; margin: 0 ; align-items: center; font-family: Kanit">
                        <div style="display: flex; align-items: center; ">
                          <div style="width: 20%;" >
                            <img :src="item.picpromotion" class="img-fluid" alt="Responsive image" style="width: 74px; display: flex; align-items: center; margin-right: 10px;">
                          </div>

                          <div style="width: 80%;">
                            <h3  v-html="item.title" style="font-size:16px; font-weight:bold; "> </h3>
                            <!-- <h3 v-html="item.name" style="font-size:16px; font-weight:bold; "> </h3> -->
                            <p v-html="item.name_case"  style="font-size:14px; font-weight:bold; text-align: center; color:#7155c6; margin-bottom: -1px;" > </p>
                            <nav style="display: flex; margin: 0; padding-bottom: auto; ">
                              <nav style="font-size:17px; font-weight: bold; margin: 0 0 0 auto; color:mediumblue;">{{item.count}}</nav>
                              <nav style="font-size:12px; margin: 6px auto 0 2px; color:#8e8d8d" v-if="item.max != null ">/ {{item.max}} ครั้ง </nav>
                            </nav>
                            <p style="font-size:12px; color:#FF0000; margin: 0; ">หมดเขตโปรโมชั่น {{item.dateTime_end}}</p>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </CCardBody>
            </CCard>
            </center>

            <!-- <nav  v-if="showTextCoupon == false && items_coupons.length > 0"  style="font-size:16px; font-weight: bold; padding-left: 10px; margin-bottom: 5px;" class="my-2">คูปองที่ได้รับ</nav> -->
            <nav  v-if="showTextCoupon == true && items_coupons.length > 0"  style="font-size:16px; font-weight: bold; padding-left: 10px; margin-bottom: 5px;" class="my-2">คูปองที่ได้รับ</nav>
            <center v-for="item in items_coupons" :key="item.id">

            <CCard v-if="item.display == true" style="width: 95%; margin: 0 0 10px 0;"  >
            <!-- <CCard v-if="item.display == false || item.display == true" style="width: 95%; margin: 0 0 10px 0;" > -->
              <CCardBody style="background-color:#e5e5e5; padding: 20px;" >
                <div style="margin: 0; padding: 0;">
                  <div style="display: flex; ">
                    <div style="width: 50%; margin: 0 5px 0 0;">
                        <h3 style="font-size:12px; font-weight:bold; margin: 0 0; ">{{item.detailname}}</h3>
                        <div style="display: flex;">
                          <div style="width: 40%;">
                            <img :src="item.picpromotion" class="img-fluid" alt="Responsive image" style="width: 54px;">
                          </div>

                          <!-- <div v-if="  item.value == 0 || item.value == null" style="width: 60%;" > -->
                          <div v-if="item.details.type == 'free' ||  item.value == 0 || item.value == null" style="width: 60%;" >
                            <h3  v-html="item.title"  style="font-size:11px; margin-top: 10px; margin-bottom: 10px; font-weight:bold; text-align: center;"> </h3>
                          </div>

                          <div v-else  style="width: 60%;">
                            <h3  v-html="item.title"  style="font-size:11px; margin: 0; font-weight:bold; text-align: center;"> </h3>
                            <p style="font-size:9.5px; font-weight:bold; color:#000; margin: 0 0 0 0; padding-top:6px; text-align: center;">มูลค่า {{item.value}} บาท</p> 
                          </div>
                        </div>
                    </div>

                    <div style="border-style: none dashed none dashed; border-width: thin; border-color:#7D7D7D;"></div>

                    <div style="width: 50%; margin: 0 0 0 5px;">
                      <p style="font-size:13px; font-weight:bold; color:#7155c6; margin: 0 0 0 0;">{{item.name_case}}</p> 

                      <template v-if="item.expireDate != ''">
                        <p style="font-size:10px; color:#FF0000; margin: 0 ; padding-top:0px">คูปองหมดอายุ<br>{{item.dateTime_end}}</p>
                        <!-- <p style="font-size:10px; color:#FF0000; margin: 0 0 0 0; padding-top:6px">สถานะ {{item.display}}</p> -->
                      </template>
                    </div>
                  </div>
                </div>
              </CCardBody>
            </CCard>
            </center>

          </template>
        </div>
        </v-tab-item>

        <v-tab-item :value="`tab-2`">
          <div style="padding-bottom: 20px">
            <div style="display: flex; padding-top: 10px;">
              <div style="width: 30%; text-align: center;">
                <img :src="picprofile" class="img-fluid" alt="Responsive image" style="width: 40px; height: 40px; border-radius: 50%;"/>
              </div>
              <div style="width: 70%;">
                <h1 style="font-size: 15px; margin: 0; color:#7155c6; ">{{textShowL1}}</h1>
                <h1 style="font-size: 15px; margin: 0 0; ">{{textShowL2}}</h1>
              </div>
            </div>
          </div>

          <v-skeleton-loader v-if="loadingdata.history" style="width: 95%; margin: 0 auto 0 auto;" type="image"></v-skeleton-loader>
          <template v-else-if="items.length > 0">
            <div style="padding-bottom: 10px;">
            <v-data-iterator :items="items" item-key="name" :items-per-page="4" hide-default-footer >
              <v-row>
                <v-col v-for="item in items" :key="item.name" cols="12" sm="6" md="4" lg="3" style="padding: 0px 15px;">
                  <center>
                    <div style="display: flex; padding-bottom: 20px; width: 95%;">
                      <div :style="item.width">
                        <v-card max-width="400" >
                          <v-card-title style="padding: 0px;"></v-card-title>
                          <v-card-text style="padding: 0px;">

                          <template v-if="item.expandtrans">
                            <div v-on:click="Expandtrans(items.indexOf(item))" style="background-repeat:no-repeat; background-size:contain; background-position:center; position: absolute; top: 50%; left: 100%; transform: translate(-50%, -50%); position: relative;" alt="Responsive image"  v-bind:style="{ 'background-image': 'url('+bgClose+')' }">⠀</div>   <!-- อักษรพิเศษที่เป็นช่องว่าง -->
                          </template>
                          <div style="display: flex;">
                            <div style="width: 40%; border-style: solid solid solid none; border-width: thin; border-color: #e6e6e6;">
                              <p style="padding: 0px; padding-right: 5px; margin: 0 0; text-align:right; font-size:10px">Transection ID </p>
                            </div>
                            <div style="width: 60%; border-style: solid none solid solid; border-width: thin; border-color: #e6e6e6;">
                              <p style="padding: 0px; padding-left: 5px; margin: 0 0; text-align:left; font-size:10px">{{item.transectionID}}</p>
                            </div>
                          </div>
                          <div style="display: flex;">
                            <div style="width: 40%; border-style: solid solid solid none; border-width: thin; border-color: #e6e6e6;">
                              <p style="padding: 0px; padding-right: 5px; margin: 0 0; text-align:right; font-size:10px">วัน/เวลา </p>
                            </div>
                            <div style="width: 60%; border-style: solid none solid solid; border-width: thin; border-color: #e6e6e6;">
                              <p style="padding: 0px; padding-left: 5px; margin: 0 0; text-align:left; font-size:10px" class="align-end">{{item.date}}</p>
                            </div>
                          </div>
                          <template v-if="!item.expandtrans">
                            <div style="display: flex;">
                              <div style="width: 40%; border-style: solid solid solid none; border-width: thin; border-color: #e6e6e6;">
                                <!-- <p style="padding: 0px; padding-right: 5px; margin: 0 0; text-align:right; font-size:10px">การซัก {{item.product.type}}</p> -->
                                <p style="padding: 0px; padding-right: 5px; margin: 0 0; text-align:right; font-size:10px">ประเภท {{item.typeProduct}}</p>
                              </div>
                              <div style="width: 60%; border-style: solid none solid solid; border-width: thin; border-color: #e6e6e6;">
                                <p style="padding: 0px; padding-left: 5px; margin: 0 0; text-align:left; font-size:10px" class="align-end">{{item.price}}</p>
                              </div>
                            </div>
                          </template>
                          <template v-if="item.expandtrans">
                            <div style="display: flex;">
                              <div style="width: 40%; border-style: solid solid solid none; border-width: thin; border-color: #e6e6e6;">
                                <p style="padding: 0px; padding-right: 5px; margin: 0 0; text-align:right; font-size:10px">สินค้า </p>
                              </div>
                              <div style="width: 60%; border-style: solid none solid solid; border-width: thin; border-color: #e6e6e6;">
                                <p style="padding: 0px; padding-left: 5px; margin: 0 0; text-align:left; font-size:10px" class="align-end">{{item.typeProduct}}</p>
                              </div>
                            </div>
                          </template>

                          <template v-if="item.expandtrans">
                            <div style="display: flex;">
                              <div style="width: 40%; border-style: solid solid solid none; border-width: thin; border-color: #e6e6e6;">
                                <p style="padding: 0px; padding-right: 5px; margin: 0 0; text-align:right; font-size:10px" >Payment Type </p>
                              </div>
                              <div style="width: 60%; border-style: solid none solid solid; border-width: thin; border-color: #e6e6e6;">
                                <p  style="padding: 0px; padding-left: 5px; margin: 0 0; text-align:left; font-size:10px" class="align-end">{{item.paymentType}}</p>
                              </div>
                            </div>
                            <div style="display: flex;">
                              <div style="width: 40%; border-style: solid solid solid none; border-width: thin; border-color: #e6e6e6;">
                                <p style="padding: 0px; padding-right: 5px; margin: 0 0; text-align:right; font-size:10px" >ราคา </p>
                              </div>
                              <div style="width: 60%; border-style: solid none solid solid; border-width: thin; border-color: #e6e6e6;">
                                <p style="padding: 0px; padding-left: 5px; margin: 0 0; text-align:left; font-size:10px" class="align-end">{{item.price}}</p>
                              </div>
                            </div>
                            <div style="display: flex;"> 
                              <div style="width: 40%; border-style: solid solid solid none; border-width: thin; border-color: #e6e6e6;">
                                <p style="padding: 0px; padding-right: 5px; margin: 0 0; text-align:right; font-size:10px">เครดิตคงเหลือ </p>
                              </div>
                              <div style="width: 60%; border-style: solid none solid solid; border-width: thin; border-color: #e6e6e6;">
                                <p style="padding: 0px; padding-left: 5px; margin: 0 0; text-align:left; font-size:10px" class="align-end">{{item.credits}}</p>
                              </div>
                            </div>
                          </template>
                          </v-card-text>
                        </v-card>
                      </div>

                      <template v-if="!item.expandtrans">
                        <div style="width: 15%; background-color: #8350ff; border-radius: 0px 5px 5px 0px;" v-on:click="Expandtrans(items.indexOf(item))">  
                          <img src="../assets/image/UI Member PHOTO/Member icon-13_2.png" class="img-fluid" alt="Responsive image" style="width: 50%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); position: relative; margin: 0 50% 0 0;"/>
                        </div>
                      </template>
                    </div>
                  </center>
                </v-col>
              </v-row>
            </v-data-iterator>
            </div>
          </template>
          <template v-else>
            <CCard style="width: 95%; margin: 0 auto; ">
              <CCardBody>
                <center>
                  <h1 style="font-size: 12px; color: #8e8d8d; margin: 0">
                    ไม่มีประวัติการใช้งาน.
                  </h1>
                </center>
              </CCardBody>
            </CCard>
          </template>
        </v-tab-item>
      </v-tabs-items>
      </div>
    </v-card>

    <v-dialog
      v-model="statuslogin.dialogpassword"
      persistent
      width="500"
    >
      <v-card style="background-color:#000;">
        <center>
          <img src="../assets/image/UI Member PHOTO/Icon Popup-01.png" class="img-fluid" alt="Responsive image" style="width: 30%; padding-top: 20px; padding-bottom: 20px;"/>
          <h1 style="color: #8350ff; font-size: 20px">ระบบรักษาความปลอดภัย</h1>
          <h1 style="color: #8350ff; font-size: 20px">ด้วยรหัส Pin</h1>
        </center>

        <CCard style="width: 85%; margin: auto;">
          <CCardBody style="background-color:#e5e5e5;">
            <center>
              <div style="padding-bottom: 0px;">
                <v-skeleton-loader v-if="loadingdata.login.pin1 && loadingdata.login.pin2" style="width: 100%; height: 100px;" type="image"></v-skeleton-loader>
                <template v-else>
                  <template v-if="loadingdata.login.pin1 == false">
                    <h1 style="font-size: 15px">กรอกรหัส Pin 4 หลัก</h1>
                    <CInput 
                      type="password" 
                      v-model="statuslogin.pin1" 
                      placeholder="pin" 
                      aria-label="pin" 
                      aria-describedby="basic-addon1"
                      style="font-family: Kanit, sans-serif;"
                      oninput=" this.value = (this.value.length > 4) ? this.value.slice(0,4) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);"
                    ></CInput>
                  </template>
                  <template v-if="loadingdata.login.pin2 == false">
                    <h1 style="font-size: 15px">กรอกรหัส Pin 4 หลักอีกครั้ง</h1>
                    <CInput 
                      type="password" 
                      v-model="statuslogin.pin2" 
                      placeholder="pin" 
                      aria-label="pin" 
                      aria-describedby="basic-addon1"
                      style="font-family: Kanit, sans-serif;"
                      oninput=" this.value = (this.value.length > 4) ? this.value.slice(0,4) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);"
                    ></CInput>
                  </template>
                  <div style="display: flex; width: 100%;">
                    <p style="color:#FF0000; margin: 0 0 0 0; padding: 0; width: 210px;">{{warningText.pin}}</p>
                    <template v-if="loadingdata.login.pin2">
                      <div style="width: 100%; display: flex; text-decoration: underline; justify-content:flex-end;" @click="DialogForgetPin()" >
                        <p :style="dataForgetPin.css" >ลืมรหัสผ่าน</p>
                        <p v-if="dataForgetPin.timeoutForgetPin!=0" :style="dataForgetPin.css">({{dataForgetPin.timeoutForgetPin}})</p>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </center>
          </CCardBody>
        </CCard>
   
        <div style="display: flex; justify-content: center; padding-top: 20px; padding-bottom: 20px;">
          <CButton v-on:click="CancelDialog()" style="background-color:#FF5C57; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
            <p style="margin: 0 10px;">ยกเลิก</p>
          </CButton>
          <CButton v-on:click="CheckPin()" style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
            <p style="margin: 0 10px;">ตกลง</p>
          </CButton>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dataForgetPin.dialog"
      width="500"
    >
      <v-card style="background-color:#FFF;">
        <div style="padding: 20px;">
          <center>
            <h1 style="color: #000; font-size: 15px">ต้องการให้เราได้ส่งรหัส Pin ใหม่ไปที่เบอร์</h1>
            <h1 style="color: #000; font-size: 15px">{{profile.numberphone}} ใช่หรือไม่ ?</h1>
            <CButton v-on:click="ForgotPassword()" style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
              <p style="margin: 0 10px;">ตกลง</p>
            </CButton>
          </center>
        </div>
      </v-card>
    </v-dialog>

  </template>
  </div>
  </v-app>
</template>

<style scoped>
</style>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
const axios = require("axios");
import config from '../config'
import { freeSet } from "@coreui/icons";
import moment from "moment";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      regisModal: false,
      items: [],
      selected: "Month",
      name: "วันเดอร์ ซักสะอาด",
      nameUser: "วันเดอร์ ซักสะอาด",
      branch:"",
      profile:"",
      picprofile: require('@/assets/image/UI Member PHOTO/Member icon-02.png'),
      bgClose: require('@/assets/image/UI Member PHOTO/Member icon-11.png'),
      sex:"",
      textShowL1:"",
      textShowL2:"",

      count_coupons : {"washer":0,"dryer":0,"wnd":0},
      items_coupons : [],
      items_promotion: [],
      // items_coupons:[{name: 'ส่วนลดจาก TRUE YOU',type: 'discount',value: 20,expireDate: '2020-11-06',"details": "ลดราคาซักผ้า"}],
      // items_promotion: {cnt: "1",cntTotal: "10",detailname: "การซักหรืออบสะสม",detailtype: "การซักผ้า",expireDate: "3/12/2563",value: "10"},
      picpromotion: require('@/assets/image/UI Member PHOTO/Member icon-02.png'),

      tabmenber:'tab-1',
      csstabActive: "width: 100%; color:#392193;",
      csstabnonActive: "width: 100%; color:#8E8D8D;",
      listcsstab: [],

      cssTransButton: "width: 15%; background-color: #8350ff; border-radius: 0px 5px 5px 0px; ",
      cssTransCrad: "width: 85%;",

      cssButtonNotExpand: "width: 15%; background-color: #8350ff; border-radius: 0px 5px 5px 0px; ",
      cssTransCradNotExpand: "width: 85%;",
      cssButtonExpand: "width: 0%; background-color: #8350ff; border-radius: 0px 5px 5px 0px; ",
      cssTransCradExpand: "width: 100%;",

      loadingdata: {
                    'credit':true,
                    'coupons':true,
                    'history':true,
                    "login":{pin1:true, pin2:true}
                   },
                   
      statuslogin:{"pin1":"","pin2":"","branch":"","numberphone":"","dialogpassword":false,"status":false},
      warningText: {"pin":""},
      tab1select:null,
      dataForgetPin: {dialog:false,timeoutForgetPin:0,css:"color:#000; margin: 0;"},
      currentTime: "",
      showTextPromotion: false,
      showTextCoupon: false,
    };
  },
  mounted() {
    this.currentTime = new Date();

    // this.regisModal = this.$store.state.popupregis
    // return moment(item.time.end).format("YYYY-MM-DD");

    var listkey = Object.keys(this.$store.state.profile)
    if(listkey.length <= 3){
      this.$router.push('/dashboard?pageww=member')
    }
    // this.$store.state.popupregis = this.$store.state.popupregis_buff

    this.profile = this.$store.state.profile
    // this.profile = JSON.parse(  localStorage.getItem('profileData') );

    this.tab1select = this.$store.state.profile.branch
    this.nameUser = this.$store.state.profile.nemeuser
    this.sex = this.$store.state.profile.sex
    this.listcsstab = [this.csstabActive,this.csstabnonActive]
    this.selectProfile(this.sex)
    this.API_machinelist().then((res) => {

    console.log("this.profile", this.profile)

    for(var i = 0; i < res.data.length; i++) {        
        res.data[i].label = res.data[i].value + res.data[i].label
        res.data[i].label = res.data[i].label.replace("Wonder Wash", " ");
        // console.log("data", res.data[i].label)
      }
      this.$store.state.profile.branchWW = [...res.data]
      var data = this.$store.state.profile.branchWW
      for(var i=0;i<data.length;i++){
        if(parseInt(data[i].value) == parseInt(this.$store.state.profile.branch)){
          data.unshift(data[i])
          data.splice(i+1, 1);
        }
      }

      this.$store.state.profile.branchWW = [...data]
      this.$store.state.profile.branchWW_org = [...data]
    });

    this.API_memberCredit(this.profile.numberphone).then((res) => {
        var listcredits = res.data
        var list_branch = []
        // console.log("API_memberCredit", listcredits)
        for(var i=0;i<listcredits.length;i++){
          list_branch.push(parseInt(listcredits[i].machineId))
          if(listcredits[i].machineId == this.$store.state.profile.branch){
            this.$store.state.profile.money = listcredits[i].value
            // this.loadingdata.credit = false
          }
          //  code test
          this.loadingdata.credit = false
          console.log('this.loadingdata.credit', this.loadingdata.credit)
        }
        if(!list_branch.includes(parseInt(this.$store.state.profile.branch))){
          this.$store.state.profile.money = 0
        }
      });

    this.API_transHistory(this.$store.state.profile.numberphone,this.$store.state.profile.branch).then((res) => {
      this.items = res.data
      for(var i=0; i<this.items.length ;i++){
        this.items[i]["width"] = "width: 85%;"
      }
      this.loadingdata.history = false
      console.log('this.loadingdata.history', this.loadingdata.history)
    });

    this.API_coupon(this.profile.numberphone,this.$store.state.profile.branch).then((res) => {
      // console.log('currentTime', this.currentTime)

      this.items_coupons = res.data.couponList.reverse();
      this.items_promotion = res.data.promotionList.reverse()

      console.log("promotion ", this.items_promotion )
      this.showTextPromotion = false
      for(var i=0;i < this.items_promotion.length;i++){
        if(this.items_promotion[i].typeId == 2 || this.items_promotion[i].typeId == 3){
          this.showTextPromotion = true
        }

        this.items_promotion[i]["dateTime_end"] = moment(this.items_promotion[i].time.end).format('DD/MM/YYYY HH:mm')
        this.items_promotion[i]["expireDate"] =  new Date(this.items_promotion[i].time.end);

        var expireDate_time  = new Date(this.items_promotion[i]["expireDate"]).getTime()
        var currentTime_time  = new Date(this.currentTime).getTime()

        // console.log('expireDate_time', expireDate_time)
        // console.log('currentTime_time', currentTime_time)
        // console.log(i,'expireDate_time >= currentTime_time', expireDate_time >= currentTime_time)

        if(expireDate_time >= currentTime_time){
          this.items_promotion[i]["display"] = true
          this.items_promotion[i]["statusDescription"] = "โปรโมชั่นยังไม่ถึงเวลากำหนด"
        }else{
          this.items_promotion[i]["display"] = false
          this.items_promotion[i]["statusDescription"] = "โปรโมชั่นเลยเวลากำหนด"
        }

        if(this.items_promotion[i].code == "CPFW1OPT"){ this.items_promotion[i]["name_case"] = "รับสิทธิ์ ซักผ้าฟรี"}
        else if(this.items_promotion[i].code == "CPFD1OPT"){  this.items_promotion[i]["name_case"] = "รับสิทธิ์ อบผ้าฟรี"}
        else if(this.items_promotion[i].code == "CPDCWASH"){  this.items_promotion[i]["name_case"] = "รับสิทธิ์ ลดราคาซักผ้า"}
        else if(this.items_promotion[i].code == "CPDCDRY"){   this.items_promotion[i]["name_case"] = "รับสิทธิ์ ลดราคาอบผ้า"}
        else if(this.items_promotion[i].code == "CPDCWND"){   this.items_promotion[i]["name_case"] = "รับสิทธิ์ ลดราคาซัก/อบ" }
        else if(this.items_promotion[i].code == "CPFWND"){    this.items_promotion[i]["name_case"] = "รับสิทธิ์ ซัก/อบฟรี"}
        else if(this.items_promotion[i].code == "CPFDORS"){   this.items_promotion[i]["name_case"] = "รับสิทธิ์ ฟรีน้ำยาซักผ้า <br/> หรือ น้ำยาปรับผ้านุ่ม 1 ซอง" }
        else if(this.items_promotion[i].code == "CPDDORS"){   this.items_promotion[i]["name_case"] = "รับสิทธิ์ ลดราคาน้ำยาซักผ้า  <br/> หรือ น้ำยาปรับผ้านุ่ม 1 ซอง"}
        else if(this.items_promotion[i].code == "CPLINE1W"){  this.items_promotion[i]["name_case"] = "รับสิทธิ์ ซักผ้าฟรี จาก LINE" }
        else if(this.items_promotion[i].code == "CPLINE1D"){  this.items_promotion[i]["name_case"] = "รับสิทธิ์ อบผ้าฟรี จาก LINE" }

        if(this.items_promotion[i].typeId == 1){
          this.items_promotion[i]["title"] = "ลดราคาตามช่วงเวลา"
          this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
        }

        else if(this.items_promotion[i].typeId == 2){
          this.items_promotion[i]["title"] = "ซักหนึ่งครั้ง"
          this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-06.png')
        }

        else if(this.items_promotion[i].typeId == 3){
          if(this.items_promotion[i].type == "wnd"){
            this.items_promotion[i]["title"] = "สะสมยอดการซักหรืออบ"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-06.png')
          }
          else if(this.items_promotion[i].type == "washer"){
            this.items_promotion[i]["title"] = "สะสมยอดการซัก"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-08.png')
          }
          else if(this.items_promotion[i].type == "dryer"){
            this.items_promotion[i]["title"] = "สะสมยอดการอบ"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-07.png')
          }
          else if(this.items_promotion[i].type == "vending"){
            this.items_promotion[i]["title"] = "สะสมยอดซื้อน้ำยา"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-05.png')
          }
        }

        else if(this.items_promotion[i].typeId == 4){
          if(this.items_promotion[i].type == "wnd"){
            this.items_promotion[i]["title"] = "ลดราคาตามจำนวนซัก/อบ"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-06.png')
          }
          else if(this.items_promotion[i].type == "washer"){
            this.items_promotion[i]["title"] = "ลดราคาตามจำนวนที่ซัก"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-08.png')
          }
          else if(this.items_promotion[i].type == "dryer"){
            this.items_promotion[i]["title"] = "ลดราคาตามจำนวนที่อบ"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-07.png')
          }
          else if(this.items_promotion[i].type == "vending"){
            this.items_promotion[i]["title"] = "ลดราคาตามจำนวนซื้อน้ำยา"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-05.png')
          }
        }
      }

      console.log("mounted coupons", this.items_coupons)
      this.showTextCoupon = false
      for(var i=0;i < this.items_coupons.length;i++){
        if(this.items_coupons[i].typeId == 2 || this.items_coupons[i].typeId == 3){
          this.showTextCoupon = true
        }

        this.items_coupons[i]["dateTime_end"] = moment(this.items_coupons[i]["expireDate"]).format('DD/MM/YYYY HH:mm')
        this.items_coupons[i]["expireDate"] =  new Date(this.items_coupons[i]["expireDate"]);

        var expireDate_time  = new Date(this.items_coupons[i]["expireDate"]).getTime()
        var currentTime_time  = new Date(this.currentTime).getTime()

        // เวลาคูปอง >= เวลาปัจจุบัน
        if(expireDate_time >= currentTime_time){
          this.items_coupons[i]["statusDescription"] = "คูปองยังไม่ถึงเวลากำหนด"
          if(  this.items_coupons[i]['used'] == false ){
            this.showTextCoupon = true
            this.items_coupons[i]["display"] = true
            this.items_coupons[i]["status"] = "แสดงคูปอง"
          }else{
            this.items_coupons[i]["display"] = false
            this.items_coupons[i]["status"] = "ไม่แสดงคูปอง"
          }
        }else{
          this.items_coupons[i]["display"] = false
          this.items_coupons[i]["statusDescription"] = "คูปองเลยเวลากำหนด"
          this.items_coupons[i]["status"] = "ไม่แสดงคูปอง"
        }

        this.items_coupons[i]["type"] =  this.items_coupons[i].details.promotionName
        this.items_coupons[i]["value"] = this.items_coupons[i].details.value
        this.items_coupons[i]["name_case"] = this.items_coupons[i].couponDetails.name
        this.items_coupons[i]["title"] = "WONDER <br> WASH"


        if( this.items_coupons[i].couponDetails.deviceType == "wnd"){
          this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-06.png')
        }
        else if(this.items_coupons[i].couponDetails.deviceType == "washer"){
          this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-08.png')
        }
        else if(this.items_coupons[i].couponDetails.deviceType == "dryer"){
          this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-07.png')
        }  
        else if(this.items_coupons[i].couponDetails.deviceType == "vending"){
          this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-05.png')
        } 
        else if( this.items_coupons[i].type== "ส่วนลดจาก TRUE YOU"){
          this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
        }

        if( this.items_coupons[i].type== "สมัครสมาชิก LINE Official ครั้งแรก"){
          this.items_coupons[i]["title"] = "สมัครสมาชิก LINE Official ครั้งแรก"

          if( this.items_coupons[i].couponDetails.name == "ซักผ้าฟรี 1 ครั้ง จาก LINE"){
            this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-17.png')
          }
          else if( this.items_coupons[i].couponDetails.name == "อบผ้าฟรี 1 ครั้ง จาก LINE"){
            this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-16.png')
          }
        }

        else if( this.items_coupons[i].type== "ส่วนลดจาก TRUE YOU"){
          this.items_coupons[i]["title"] = "ส่วนลดจาก <br> TRUE YOU"
          this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
        }

        else if( this.items_coupons[i].type== "ซัก/อบ X ลด ซัก/อบ X"){
          this.items_coupons[i]["title"] = "WONDER <br> WASH"
          // this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
        }
      }

      this.loadingdata.coupons = false
    });
  },

  methods: {
    cssTab(numtab){
      for(var i=1;i<=this.listcsstab.length;i++){
        if(i == numtab){
          this.listcsstab[i-1] = this.csstabActive
        }
        else{
          this.listcsstab[i-1] = this.csstabnonActive
        }
      }
    },
    selectProfile(sex) {
      if(sex == "male"){
          this.picprofile = this.profile.pictureUrl
          this.textShowL1 = "สวัสดีครับ ,"
          this.textShowL2 = "คุณ " + this.nameUser
        }
        else if(sex == "female"){
          this.picprofile = this.profile.pictureUrl
          this.textShowL1 = "สวัสดีค่ะ ,"
          this.textShowL2 = "คุณ " + this.nameUser
        }
        else if(sex == "not specified" || sex == "not specif"){
          this.picprofile = this.profile.pictureUrl
          this.textShowL1 = "สวัสดีค่ะ ,"
          this.textShowL2 = "คุณ " + this.nameUser
        }
    },
    CheckPin(){
 
      if(this.statuslogin.pin1.length == 4 && this.loadingdata.login.pin1 == false && this.loadingdata.login.pin2){
         
        let data = { "pin":this.statuslogin.pin1,"numberphone": this.$store.state.profile.numberphone,"branch": parseInt(this.branch) }
        this.API_Login(data).then((res) => {
          this.statuslogin.status = res.data.comparepin
          this.statuslogin.dialogpassword = !res.data.comparepin

          if(res.data.comparepin){
            this.warningText.pin = ""
            let tab1 = document.getElementById('selectTab1')
            this.$store.state.profile.branch = tab1.value
            
            let dataBranch = {
              'userid':this.$store.state.profile.userId,
              "numberphone":this.$store.state.profile.numberphone,
              "branch":this.$store.state.profile.branch,
              "pin":this.statuslogin.pin1
            }
            
            this.API_ChangeMainBranch(dataBranch).then((res)=>{
              // console.log("log success", res.data.success)
              if(res.data.success){

                this.LoadDataOfBranch()
                this.CancelDialog()
              }
            }
            );
          }
          else{
            this.warningText.pin = "*รหัสผ่านไม่ถูกต้อง"
          }
        });
      }
      // ไม่เคยใช้งาน

      if(this.statuslogin.pin1 == this.statuslogin.pin2 && this.statuslogin.pin1.length == 4 && this.loadingdata.login.pin1 == false && this.loadingdata.login.pin2 == false){
        let data = {
                      "numberphone": this.$store.state.profile.numberphone,
                      "rqPasswordKiosk": true,
                      "pin": this.statuslogin.pin1,
                      "branch":this.$store.state.profile.branch,
                   }
        this.API_Createuser(data).then((res)=>{
          console.log("-----ไม่เคยใช้งาน----",res.data)

          if(res.data.status){
            let tab1 = document.getElementById('selectTab1')
            this.$store.state.profile.branch = tab1.value

            let dataBranch = {
              'userid':this.$store.state.profile.userId,
              "numberphone":this.$store.state.profile.numberphone,
              "branch":this.$store.state.profile.branch,
              "pin":this.statuslogin.pin1
            }

            // console.log("log", dataBranch)
            // setTimeout(() => {
            //   this.API_ChangeMainBranch(dataBranch).then((res)=>{
            //   console.log("log success", res.data.success)
            //   if(res.data.success){
            //     this.LoadDataOfBranch()
            //     this.CancelDialog()
            //   }
            // });
            // }, 1000);
            
            // console.log("log ", dataBranch)

            this.API_ChangeMainBranch(dataBranch).then((res)=>{
              // console.log("log success", res.data.success)
              if(res.data.success){
                this.LoadDataOfBranch()
                this.CancelDialog()
                
              }
            });

          }
        })
      }
      if(this.statuslogin.pin1 == this.statuslogin.pin2 && this.statuslogin.pin1.length > 4){
        this.warningText.pin = "*กำหนด Pin ด้วยตัวเลข 4 หลัก"
      }
      if(this.statuslogin.pin1 != this.statuslogin.pin2 && this.loadingdata.login.pin2 == false){
        this.warningText.pin = "*กรอก pin ให้ตรงกัน"
      }
    },
    onChangeBranch(e){
      const { value } = e.target
      this.branch = value
      if(this.$store.state.profile.setting.AskPinLine == "1"){
        this.statuslogin.dialogpassword = true
      }
      this.loadingdata.login = {pin1:true, pin2:true}
      this.warningText.pin = ""

      this.API_Checkmemberstatus(this.$store.state.profile.numberphone,value,this.$store.state.profile.userId).then((res)=>{
        if(res.data.password){
          this.loadingdata.login = {pin1:false, pin2:true}
          if(this.$store.state.profile.setting.AskPinLine != "1"){
            let tab1 = document.getElementById('selectTab1')
            this.$store.state.profile.branch = tab1.value
            let dataBranch = {
              'userid':this.$store.state.profile.userId,
              "numberphone":this.$store.state.profile.numberphone,
              "branch":this.$store.state.profile.branch,
              "pin":this.statuslogin.pin1
            }
            this.API_ChangeMainBranch(dataBranch).then((res)=>{
              if(res.data.success){
                this.LoadDataOfBranch()
                this.CancelDialog()
              }
            });
          }
        }
        else{
          this.statuslogin.dialogpassword = true
          this.loadingdata.login = {pin1:false, pin2:false}
          this.warningText.pin = ""
        }
      })

      
    },
    CancelDialog(){
      this.statuslogin.dialogpassword = false; 
      this.statuslogin.pin1 = '' 
      this.statuslogin.pin2 = '' 

      let tab1 = document.getElementById('selectTab1')
      tab1.value = this.$store.state.profile.branch
    },
    LoadDataOfBranch(){
      this.loadingdata.credit = true
      this.loadingdata.coupons = true
      this.loadingdata.history = true

      this.SortListBranchWW().then((data)=>{
        var tab1 = document.getElementById('selectTab1')
        tab1.value = this.$store.state.profile.branch
      })

      this.API_memberCredit(this.$store.state.profile.numberphone).then((res) => {
        var listcredits = res.data
        var list_branch = []
        // console.log("API_memberCredit", listcredits)
        for(var i=0;i<listcredits.length;i++){
          list_branch.push(parseInt(listcredits[i].machineId))
          if(listcredits[i].machineId == this.$store.state.profile.branch){
            this.$store.state.profile.money = listcredits[i].value
          }
        }
        if(!list_branch.includes(parseInt(this.$store.state.profile.branch))){
          this.$store.state.profile.money = 0
        }
        this.loadingdata.credit = false
      });

      this.API_coupon(this.profile.numberphone,this.$store.state.profile.branch).then((res) => {
        // console.log('currentTime', this.currentTime)

        this.items_coupons = res.data.couponList.reverse();
        this.items_promotion = res.data.promotionList.reverse()

        console.log("promotion ", this.items_promotion )
        this.showTextPromotion = false
        for(var i=0;i < this.items_promotion.length;i++){
          if(this.items_promotion[i].typeId == 2 || this.items_promotion[i].typeId == 3){
            this.showTextPromotion = true
          }

          this.items_promotion[i]["dateTime_end"] = moment(this.items_promotion[i].time.end).format('DD/MM/YYYY HH:mm')
          this.items_promotion[i]["expireDate"] =  new Date(this.items_promotion[i].time.end);

          var expireDate_time  = new Date(this.items_promotion[i]["expireDate"]).getTime()
          var currentTime_time  = new Date(this.currentTime).getTime()

          // console.log('expireDate_time', expireDate_time)
          // console.log('currentTime_time', currentTime_time)
          // console.log(i,'expireDate_time >= currentTime_time', expireDate_time >= currentTime_time)

          if(expireDate_time >= currentTime_time){
            this.items_promotion[i]["display"] = true
            this.items_promotion[i]["statusDescription"] = "โปรโมชั่นยังไม่ถึงเวลากำหนด"
          }else{
            this.items_promotion[i]["display"] = false
            this.items_promotion[i]["statusDescription"] = "โปรโมชั่นเลยเวลากำหนด"
          }

        if(this.items_promotion[i].code == "CPFW1OPT"){ this.items_promotion[i]["name_case"] = "รับสิทธิ์ ซักผ้าฟรี"}
          else if(this.items_promotion[i].code == "CPFD1OPT"){  this.items_promotion[i]["name_case"] = "รับสิทธิ์ อบผ้าฟรี"}
          else if(this.items_promotion[i].code == "CPDCWASH"){  this.items_promotion[i]["name_case"] = "รับสิทธิ์ ลดราคาซักผ้า"}
          else if(this.items_promotion[i].code == "CPDCDRY"){   this.items_promotion[i]["name_case"] = "รับสิทธิ์ ลดราคาอบผ้า"}
          else if(this.items_promotion[i].code == "CPDCWND"){   this.items_promotion[i]["name_case"] = "รับสิทธิ์ ลดราคาซัก/อบ" }
          else if(this.items_promotion[i].code == "CPFWND"){    this.items_promotion[i]["name_case"] = "รับสิทธิ์ ซัก/อบฟรี"}
          else if(this.items_promotion[i].code == "CPFDORS"){   this.items_promotion[i]["name_case"] = "รับสิทธิ์ ฟรีน้ำยาซักผ้า <br/> หรือ น้ำยาปรับผ้านุ่ม 1 ซอง" }
          else if(this.items_promotion[i].code == "CPDDORS"){   this.items_promotion[i]["name_case"] = "รับสิทธิ์ ลดราคาน้ำยาซักผ้า <br/> หรือ น้ำยาปรับผ้านุ่ม 1 ซอง"}
          else if(this.items_promotion[i].code == "CPLINE1W"){  this.items_promotion[i]["name_case"] = "รับสิทธิ์ ซักผ้าฟรี จาก LINE" }
          else if(this.items_promotion[i].code == "CPLINE1D"){  this.items_promotion[i]["name_case"] = "รับสิทธิ์ อบผ้าฟรี จาก LINE" }

          if(this.items_promotion[i].typeId == 1){
            this.items_promotion[i]["title"] = "ลดราคาตามช่วงเวลา"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
          }

          else if(this.items_promotion[i].typeId == 2){
            this.items_promotion[i]["title"] = "ซักหนึ่งครั้ง"
            this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-06.png')
          }

          else if(this.items_promotion[i].typeId == 3){
            if(this.items_promotion[i].type == "wnd"){
              this.items_promotion[i]["title"] = "สะสมยอดการซักหรืออบ"
              this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-06.png')
            }
            else if(this.items_promotion[i].type == "washer"){
              this.items_promotion[i]["title"] = "สะสมยอดการซัก"
              this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-08.png')
            }
            else if(this.items_promotion[i].type == "dryer"){
              this.items_promotion[i]["title"] = "สะสมยอดการอบ"
              this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-07.png')
            }
            else if(this.items_promotion[i].type == "vending"){
              this.items_promotion[i]["title"] = "สะสมยอดซื้อน้ำยา"
              this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-05.png')
            }
          }

          else if(this.items_promotion[i].typeId == 4){
            if(this.items_promotion[i].type == "wnd"){
              this.items_promotion[i]["title"] = "ลดราคาตามจำนวนซัก/อบ"
              this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-06.png')
            }
            else if(this.items_promotion[i].type == "washer"){
              this.items_promotion[i]["title"] = "ลดราคาตามจำนวนที่ซัก"
              this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-08.png')
            }
            else if(this.items_promotion[i].type == "dryer"){
              this.items_promotion[i]["title"] = "ลดราคาตามจำนวนที่อบ"
              this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-07.png')
            }
            else if(this.items_promotion[i].type == "vending"){
              this.items_promotion[i]["title"] = "ลดราคาตามจำนวนซื้อน้ำยา"
              this.items_promotion[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-05.png')
            }
          }
        }

        console.log("mounted coupons", this.items_coupons)
        this.showTextCoupon = false
        for(var i=0;i < this.items_coupons.length;i++){
          if(this.items_coupons[i].typeId == 2 || this.items_coupons[i].typeId == 3){
            this.showTextCoupon = true
          }

          this.items_coupons[i]["dateTime_end"] = moment(this.items_coupons[i]["expireDate"]).format('DD/MM/YYYY HH:mm')
          this.items_coupons[i]["expireDate"] =  new Date(this.items_coupons[i]["expireDate"]);

          var expireDate_time  = new Date(this.items_coupons[i]["expireDate"]).getTime()
          var currentTime_time  = new Date(this.currentTime).getTime()


          // เวลาคูปอง >= เวลาปัจจุบัน
          if(expireDate_time >= currentTime_time){
            this.items_coupons[i]["statusDescription"] = "คูปองยังไม่ถึงเวลากำหนด"
            if(  this.items_coupons[i]['used'] == false ){
              this.showTextCoupon = true
              this.items_coupons[i]["display"] = true
              this.items_coupons[i]["status"] = "แสดงคูปอง"
            }else{
              this.items_coupons[i]["display"] = false
              this.items_coupons[i]["status"] = "ไม่แสดงคูปอง"
            }
          }else{
            this.items_coupons[i]["display"] = false
            this.items_coupons[i]["statusDescription"] = "คูปองเลยเวลากำหนด"
            this.items_coupons[i]["status"] = "ไม่แสดงคูปอง"
          }

          this.items_coupons[i]["type"] =  this.items_coupons[i].details.promotionName
          this.items_coupons[i]["value"] = this.items_coupons[i].details.value
          this.items_coupons[i]["name_case"] = this.items_coupons[i].couponDetails.name
          this.items_coupons[i]["title"] = "WONDER <br> WASH"


          if( this.items_coupons[i].couponDetails.deviceType == "wnd"){
            this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-06.png')
          }
          else if(this.items_coupons[i].couponDetails.deviceType == "washer"){
            this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-08.png')
          }
          else if(this.items_coupons[i].couponDetails.deviceType == "dryer"){
            this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-07.png')
          }  
          else if(this.items_coupons[i].couponDetails.deviceType == "vending"){
            this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-05.png')
          } 
          else if( this.items_coupons[i].type== "ส่วนลดจาก TRUE YOU"){
            this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
          }

          if( this.items_coupons[i].type== "สมัครสมาชิก LINE Official ครั้งแรก"){
            this.items_coupons[i]["title"] = "สมัครสมาชิก LINE Official ครั้งแรก"

            if( this.items_coupons[i].couponDetails.name == "ซักผ้าฟรี 1 ครั้ง จาก LINE"){
              this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-17.png')
            }
            else if( this.items_coupons[i].couponDetails.name == "อบผ้าฟรี 1 ครั้ง จาก LINE"){
              this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-16.png')
            }
          }

          else if( this.items_coupons[i].type== "ส่วนลดจาก TRUE YOU"){
            this.items_coupons[i]["title"] = "ส่วนลดจาก <br> TRUE YOU"
            this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
          }

          else if( this.items_coupons[i].type== "ซัก/อบ X ลด ซัก/อบ X"){
            this.items_coupons[i]["title"] = "WONDER <br> WASH"
            // this.items_coupons[i]["picpromotion"] = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
          }
        }

        this.loadingdata.coupons = false
      });

      this.API_transHistory(this.$store.state.profile.numberphone,this.$store.state.profile.branch).then((res) => {
        this.items = res.data
        console.log('API_transHistory', this.items)
        for(var i=0; i<this.items.length ;i++){
          this.items[i]["width"] = "width: 85%;"
        }
        this.loadingdata.history = false
      });
    },
    SortListBranchWW(){
      return new Promise((resolve, reject) => {
        let data = [...this.$store.state.profile.branchWW_org]
        for(var i=0;i<data.length;i++){
          if(parseInt(data[i].value) == parseInt(this.$store.state.profile.branch)){
            data.unshift(data[i])
            data.splice(i+1, 1);
          }
        }
        this.$store.state.profile.branchWW = [...data]
        resolve(data)
      })
    },
    Expandtrans(index){
      if(this.items[index].expandtrans){
        this.items[index].expandtrans = false
        this.items[index].width = "width: 85%;"
        this.items[i]["widthrow"] = ["width: 40%;","width: 60%;"]
      }
      else{
        this.items[index].expandtrans = true
        this.items[index].width = "width: 100%;"
        this.items[i]["widthrow"] = ["width: 50%;","width: 50%;"]
      }
    },
    DialogForgetPin(){
      if(this.dataForgetPin.timeoutForgetPin == 0){
        this.dataForgetPin.dialog = true
      }
    },
    ForgotPassword(){
      this.dataForgetPin.dialog = false
      if(this.dataForgetPin.timeoutForgetPin == 0){
        this.dataForgetPin.timeoutForgetPin = 180
        this.dataForgetPin.css = "color:#5f5e5e; margin: 0;"
        var timer =  setInterval(() => { 
                          if(this.dataForgetPin.timeoutForgetPin != 0){
                            --this.dataForgetPin.timeoutForgetPin
                          }
                          else{
                            clearInterval(timer)
                            this.dataForgetPin.css = "color:#5f5e5e; margin: 0;"
                          }
                        }, 1000);
        let data =  {
                      "userId": this.profile.userId,
                      "numberphone": this.profile.numberphone,
                      "branch":this.branch
                    }
        this.API_ForgetPin(data).then((res)=>{
          // console.log("res", res)
        })
      }
    },
    API_ForgetPin(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/forgetpin", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_memberCredit(refNumber){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json"};
        axios.get(config.server+"/credits?refNumber="+refNumber,{
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_coupon(refNumber,machineId){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json"};
        axios.get(config.server+"/coupon?refNumber="+refNumber+"&machineId="+machineId,{
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_transHistory(refNumber,machineId){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json"};
        axios.get(config.server+"/history?refNumber="+refNumber+"&machineId="+machineId,{
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_machinelist(){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json"};
        axios.get(config.server+"/machinelist",{
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_Login(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/login", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_ChangeMainBranch(data) {
      console.log("API_ChangeMainBranch")
      console.log("data", data)

      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server+"/changemainbranch", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_Checkmemberstatus(refNumber,machineId,datauserid){
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json"};
        axios.get(config.server+'/checkmemberstatus/?refNumber='+refNumber+'&machineId='+machineId+'&userid='+datauserid,{
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_Createuser(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server+"/memberpin", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    
  },
};
</script>